<template>
    <div class="photo-input">
        <div class="photo center" @click="onClickPhoto">
            <div v-if="isEssential" class="essential" v-html="$translate('ESSENTIAL')" />
            <div class="img-container" v-if="customPhoto.url">
                <i @click.stop="onClickClear" class="material-icons">clear</i>
                <img @click.stop="onClickPhoto" :src="customPhoto.url" />
            </div>
            <div v-else class="flex center">
                <i class="material-icons plus f-24 m-b-10">add</i>
            </div>
        </div>
        <input
            ref="imageUploader"
            type="file"
            class="image-input display-none"
            @change="onChangeImage($event)"
            accept="image/*"
        />
    </div>
</template>

<script>
export default {
    name: 'PhotoInputArea',
    props: {
        isEssential: Boolean,
    },
    data: () => ({
        customPhoto: {
            blob: null,
            url: null,
        },
    }),
    computed: {
        optionExplain() {
            if (this.isOption) return this.$translate('PHOTO_OPTIONAL')
            else return this.$translate('PHOTO')
        },
    },
    methods: {
        onClickPhoto() {
            this.$refs.imageUploader.click()
        },
        onClickClear() {
            this.customPhoto = {
                blob: null,
                url: null,
            }
            this.$emit('clearPhoto')
        },
        onChangeImage(e) {
            const file = e.target.files[0]
            this.$refs.imageUploader.value = ''

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile: file,
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return
                    this.customPhoto.url = URL.createObjectURL(croppedFile)
                    this.customPhoto.blob = croppedFile
                    this.$emit('submitPhoto', this.customPhoto)
                })
        },
    },
}
</script>

<style scoped lang="scss">
.section-title {
    @include f-medium;
}
.photo-input {
    margin-top: 32px;
}

.img-container {
    position: relative;
    width: 100%;
    height: 100%;

    i {
        border-radius: 50%;
        padding: 4px;
        background: rgba(0, 0, 0, 0.3);
        color: white;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 99999;
    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
}

.photo {
    $section-side-padding: 16px;
    $bottom-button-height: 80px;
    position: relative;

    width: calc(100vw - #{$section-side-padding} * 2);
    height: calc(100vw - #{$section-side-padding} * 2);
    background-color: rgba($purple-primary, 0.05);
    border-radius: 10px;
    border: 1px solid rgba($purple-primary, 0.5);

    .essential {
        position: absolute;
        top: 16px;
        left: 16px;
        background: $purple-primary;
        color: white;
        border-radius: 6px;
        padding: 1px 8px;
        font-size: 12px;
        @include f-medium;
    }

    .plus {
        color: $purple-primary;
    }

    .btn {
        background-color: $grey-09;
        height: 40px;
        padding: 10px 30px;
        z-index: inherit;
    }

    &.white {
        background: white;
        width: calc(100vw - 40px);
        height: calc(100vw - 40px);
        border: 1px solid $grey-03;
    }
}
</style>
