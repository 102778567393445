<template>
    <div class="verification-badges-create">
        <StackRouterHeaderBar />
        <div class="scroll" style="height: calc(100vh - 131px); overflow-y: auto">
            <div class="title" v-html="headerTitle" />

            <div class="badge-desc" v-html="badgeDesc" />
            <div class="img-cover w-100 m-b-20" v-if="realNameVerify">
                <img class="real-name" :src="require('@/assets/images/verification-badge/realname-verification.png')" />
            </div>
            <PhotoInputArea v-else :isEssential="true" @submitPhoto="updatePhoto" @clearPhoto="clearPhoto" />
        </div>
        <BottomButton @click="submit" :disabled="!realNameVerify && !customPhoto.blob" :label="$translate('VERIFY')" />
    </div>
</template>

<script>
import PhotoInputArea from '@/routes/verification-badges/components/PhotoInputArea'
import verificationBadgeService from '@/services/verification-badge'

export default {
    name: 'VerificationBadgesCreatePage',
    components: {
        PhotoInputArea,
    },
    props: {
        badge: Object,
    },
    data: () => ({
        showTitle: false,
        customPhoto: {},
    }),
    watch: {
        '$store.getters.niceData': {
            handler(newVal) {
                const checkGender = niceGender => {
                    const vbGender = this.$store.getters.me.gender
                    return !(Number(niceGender) === vbGender)
                }

                if (checkGender(newVal.gender)) this.finishVerification(newVal)
                else {
                    this.$modal.basic({
                        title: '뱃지 획득에 실패했어요',
                        body: '가입시 성별과 달라요. 뱃지를 획득하려면 카카오톡 플러스 친구에 문의 주세요!',
                        buttons: [
                            {
                                label: '확인',
                                class: 'btn-default',
                            },
                        ],
                    })
                }
            },
            deep: true,
        },
    },
    computed: {
        headerTitle() {
            return this.badge.name
        },
        badgeDesc() {
            return this.badge.verification_method
        },
        realNameVerify() {
            return this.badge.id === 19
        },
    },
    methods: {
        async finishVerification(niceData) {
            const responseId = niceData.response_id
            const payload = this.preparedPayload()
            payload.append('response_id', responseId)

            try {
                await verificationBadgeService.create(this.$store.getters.me.id, payload)

                this.$toast.success('축하드려요!🎉 실명이 인증되었어요!')
                await this.$store.dispatch('loadMyVerificationBadges')
            } catch (e) {
                this.$toast.error(e.data.msg)
            }

            this.$stackRouter.pop()
        },
        updatePhoto(photo) {
            this.customPhoto = photo
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'VerifPage_Click_PhotoUpload',
                    option: {
                        label: this.badge.id === 15 ? 'school' : this.badge === 16 ? 'job' : 'marriage',
                    },
                },
            })
        },
        clearPhoto() {
            this.customPhoto = {
                blob: null,
                url: null,
            }
        },
        async submit() {
            if (this.realNameVerify) {
                this.$nativeBridge.postMessage({ action: 'verification' })
                return
            }

            if (!this.customPhoto.blob) {
                this.$toast.error('사진 1장은 필수입니다.')
                return
            }
            const payload = this.preparedPayload()
            try {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'VerifPage_Click_Verify',
                        option: {
                            label: this.badge.id === 15 ? 'school' : this.badge === 16 ? 'job' : 'marriage',
                        },
                    },
                })
                this.$loading(true)
                await verificationBadgeService.create(this.$store.getters.me.id, payload)
            } catch (e) {
                console.log(e)
            } finally {
                this.$loading(false)
            }

            this.$store.dispatch('loadMyVerificationBadges')
            this.$stackRouter.pop()
        },
        preparedPayload() {
            const payload = new FormData()
            payload.append('verification_badge_id', this.badge.id)
            payload.append('verification_type', this.realNameVerify ? 'auto' : 'photo')

            if (!this.realNameVerify) {
                payload.append(`photo1`, this.customPhoto.blob, this.customPhoto.name)
            }

            return payload
        },
    },
}
</script>

<style lang="scss" scoped>
.verification-badges-create {
    .title {
        padding: 0 16px;
        color: black;
        font-size: 24px;
        @include f-medium;
    }
    .badge-desc {
        span {
            font-size: 15px;
        }
        color: black;
        font-size: 14px;
        letter-spacing: -0.21px;

        margin: 40px 16px 24px 16px;
        padding: 16px;
        background: $grey-01;
        border-radius: 12px;
        line-height: 24px;
    }

    ::v-deep .photo {
        margin: 16px;
    }

    .desc {
        text-align: center;
        color: $grey-07;
        font-size: 12px;
        margin-bottom: 31px;
    }

    .img-cover {
        display: flex;
        justify-content: center;
        .real-name {
            max-width: 290px;
            height: auto;
        }
    }
}
</style>
